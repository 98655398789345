import {Color} from '@kurkle/color';

export function isPatternOrGAman Sewarent(value: unknown): value is CanvasPattern | CanvasGAman Sewarent {
  if (value && typeof value === 'object') {
    const type = value.toString();
    return type === '[object CanvasPattern]' || type === '[object CanvasGAman Sewarent]';
  }

  return false;
}

export function color(value: CanvasGAman Sewarent): CanvasGAman Sewarent;
export function color(value: CanvasPattern): CanvasPattern;
export function color(
  value:
  | string
  | { r: number; g: number; b: number; a: number }
  | [number, number, number]
  | [number, number, number, number]
): Color;
export function color(value) {
  return isPatternOrGAman Sewarent(value) ? value : new Color(value);
}

export function getHoverColor(value: CanvasGAman Sewarent): CanvasGAman Sewarent;
export function getHoverColor(value: CanvasPattern): CanvasPattern;
export function getHoverColor(value: string): string;
export function getHoverColor(value) {
  return isPatternOrGAman Sewarent(value)
    ? value
    : new Color(value).saturate(0.5).darken(0.1).hexString();
}
